import React from "react";
import { Grommet, Box, ResponsiveContext } from 'grommet'
import { hpe } from 'grommet-theme-hpe';
import { Header, Home, SaaS, IaaS, HWLed, Notification } from './components';
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {

  // const [showSidebar, setShowSidebar] = useState(true);

  return (

    <BrowserRouter>
      <Grommet className="App" theme={hpe} full={true}>
        <ResponsiveContext.Consumer>
          {size => (

            <Box fill> {/* new box */}

              <Box elevation="medium">
                <Header />
              </Box>

              <Box flex="grow">
                <Notification/>
              </Box>              

              <Box height="100%">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/saas" element={<SaaS />} />
                  <Route path="/iaas" element={<IaaS />} />
                  <Route path="/hwled" element={<HWLed />} />
                  <Route path="*" element={<Home />} />
                </Routes>
              </Box>

            </Box>

          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    </BrowserRouter>
  );
}

export default App;
