import React from 'react';
//import styled from 'styled-components';
import { Box, Text, Footer} from 'grommet';
import { Hpe as HpeIcon } from 'grommet-icons';
import { AppFooter } from '../components';

const HWLedPage = () => {

  //const [value, setValue] = React.useState('');
  //const size = useContext(ResponsiveContext);
  //const [focused] = useState(false);
  //const [focused, setFocused] = useState(false);

  return (

    <Box background="background-back" elevation="medium" flex>

        <Box flex justify="center">

          <Box direction="row" justify="center">
            <HpeIcon color="brand" alignSelf="center"/>
          </Box>  

          <Box direction="row" justify="center" margin={{ left: "20px", right: "20px" }}>
            <Text size="20pt" textAlign="center" style={{fontWeight: 'bold'}}>HW Led Value Chain coming soon...</Text>
          </Box>  

        </Box>         

        <Footer pad="xsmall" justify="center">
            <Text size="11pt" style={{fontStyle: 'italic', fontWeight: 'bold'}}>Powered by ITaaS</Text>
        </Footer>

        <AppFooter />

    </Box>


  );
};

export { HWLedPage as HWLed };