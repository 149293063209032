import { React, useContext, useState } from 'react';
//import styled from 'styled-components';
import { Box, Grid, ResponsiveContext, Page, PageContent, Footer, PageHeader, Text } from 'grommet';
import { CostByMonth, CostByService, CostByYear, RulesAudit } from './../content/home';
import { AppFooter } from '../components';
// import {
//   convertDatesToFeatures,
//   defaultWindow,
//   formatCurrency,
//   linearRegression,
//   mean,
//   DEMO_DATE,
//   REPORT_WINDOW_MAP,
// } from './../helpers';

const HomePage = () => {

  //const [value, setValue] = React.useState('');
  //const size = useContext(ResponsiveContext);
  //const [focused] = useState(false);
  //const [focused, setFocused] = useState(false);

  return (

    <Page background="background-back" flex="grow">

      <PageContent>

        <PageHeader title="Hello!" size="medium"
          pad={{ vertical: 'small' }}
          subtitle={`Welcome to the HPE Sales Cockpit.`}/>

        <Content />

      </PageContent>
      
      <Footer pad="xsmall" justify="center">
        <Text size="11pt" style={{fontStyle: 'italic', fontWeight: 'bold'}}>Powered by HPE</Text>
      </Footer>

      <AppFooter />

    </Page>
  );
};

const parentGrid = {
  columns: {
    xsmall: ['100%'],
    small: ['100%'],
    medium: [
      ['small', 'large'],
      ['small', 'large'],
    ],
    large: [
      ['small', 'medium'],
      ['small', 'medium'],
      ['small', 'medium'],
    ],
    xlarge: [
      ['medium', 'large'],
      ['medium', 'large'],
      ['medium', 'large'],
    ],
  },
  gap: {
    xsmall: 'large',
    small: 'large',
    medium: 'medium',
    large: 'large',
    xlarge: 'large',
  },
};

const defaultPeriod = 'Last 30 Days';

const Content = () => {
  const size = useContext(ResponsiveContext);
  const [period] = useState(defaultPeriod);

  return (
 
 <main>
    <Box>
      <Grid columns={parentGrid.columns[size]} gap={parentGrid.gap[size]}>
        <CostByService period={period} />
        <CostByMonth period="Last Year" />
        <CostByYear period="Lifetime" />
        <RulesAudit period="Last Year" />
      </Grid>
    </Box>
</main>
  );
};

export { HomePage as Home };

